.after-review-img {
  width: 100%;
  height: 0;
  padding-bottom: 35%;
  background-image: url('../../img/detalis/after-review-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 10px;
}

.naver-map {
  width: 100%;
  height: 300px;
  margin-bottom: 24px;
}

.map-container-text-addr {
  text-align: center;
  line-height: 1.4;
}

.map-container-title {
  font-size: 11px;
  font-weight: 600;
  color: #004845;
  text-align: center;
  margin-bottom: 12px;
}

.map-container-text-desc {
  padding: 12px 18px;
  font-size: 10px;
  color: #707070;
}

.map-container-text-bus {
  padding: 0 24px;
  font-size: 10px;
  color: #707070;
  margin-bottom: 36px;
}

.map-container-text-link {
  display: block;
  margin: 0 auto;
  width: 60%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #82061b;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}

.map-container-text-link strong {
  display: none;
}

.map-naver-link-mobile {
  display: block;
  background-color: #17ce5f;
  color: #fff;
  font-weight: 600;
  padding: 12px 36px;
  margin-bottom: 12px;
  text-align: center;
}

.map-naver-link-desk {
  display: none;
}

@media screen and (min-width: 994px) {
  .map-container {
    padding: 0 48px;
    position: relative;
  }

  .map-container-title {
    display: none;
  }

  .naver-map {
    width: 50%;
    height: 500px;
  }

  .map-container-text {
    width: 40%;
    position: absolute;
    right: 30px;
    top: 30px;
    white-space: nowrap;
  }
  .map-container-text-addr {
    display: block;
    font-size: 29px;
    line-height: 1.7;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
  }

  .map-container-text-desc {
    margin-bottom: 24px;
  }

  .map-container-text-desc,
  .map-container-text-bus {
    padding: 0;
    font-size: 19px;
    letter-spacing: -0.05em;
  }

  .map-container-text-link {
    margin-top: 48px;
    font-size: 34px;
    white-space: nowrap;
    padding: 24px 0;
    border-radius: 40px;
  }
  .map-container-text-link strong {
    display: inline;
  }

  .map-container-text-link span,
  .map-naver-link-mobile {
    display: none;
  }

  .map-naver-link-desk {
    display: inline-block;
    background-color: #17ce5f;
    color: #fff;
    font-weight: 600;
    padding: 16px 48px;
    margin-bottom: 12px;
    text-align: center;
  }
}
