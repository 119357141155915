.rehabilitation-intro {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 3px;
  white-space: nowrap;
}

.rehabilitation-intro-text {
  margin-right: 10px;
}

.rehabilitation-intro-text-title {
  font-size: 1.2em;
  color: #004845;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 500;
}

.rehabilitation-intro-text-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 12px;
}

.rehabilitation-intro-img {
  background-image: url('../../img/rehabilitation-intro/rehabilitation-logo.jpg');
  width: 50%;
  height: 0;
  padding-bottom: 45%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

@media screen and (min-width: 994px) {
  .rehabilitation-intro {
    margin-bottom: 70px;
  }

  .rehabilitation-intro-text-title {
    font-size: 36px;
    letter-spacing: -0.03em;
    margin-bottom: 36px;
  }
  .rehabilitation-intro-text-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
  }
  .rehabilitation-intro-img {
    padding-bottom: 30%;
  }
}
