.review-container-more-desk {
  display: none;
}

.review-container-more {
  display: block;
  text-align: center;
  margin-bottom: 24px;
}

.review-container-more a {
  font-size: 12px;
  color: #fff;
  background-color: #004845;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 24px;
  text-align: center;
}

@media screen and (min-width: 994px) {
  .review-container-more {
    display: none;
  }

  .review-container-more-desk {
    display: block;
    text-align: center;
    margin-bottom: 48px;
  }

  .review-container-more-desk a {
    font-size: 33px;
    color: #fff;
    background-color: #004845;
    display: inline-block;
    padding: 12px 48px;
    border-radius: 36px;
    text-align: center;
  }
}
