.rehabilitation-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 120%;
  background-image: url('../../img/rehabilitation-intro/rehabilitation-main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 994px) {
  .rehabilitation-container-main-img {
    width: 100%;
    padding-bottom: 70%;
    background-image: url('../../img/rehabilitation-intro/rehabilitation-main-img-desk.jpg');
  }

  .facility-container-blog-main {
    margin: 100px 0;
  }
  .facility-container-blog-title-main {
    margin-bottom: 70px;
  }
}
