.main-intro-img {
  width: 100%;
  height: 0;
  padding-bottom: 110%;
  background-image: url("../../img/main/main-img-sm.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 24px;
}

.main-intro-text {
  text-align: center;
  margin-bottom: 24px;
}

.main-intro-title {
  margin: 0 auto;
  width: 60%;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  margin-bottom: 18px;
}

.main-intro-desc {
  font-size: 14px;
  line-height: 1.5714285714;
  letter-spacing: -0.02em;
  color: #646464;
  margin-bottom: 24px;
}

.main-intro-link {
  display: inline-block;
  color: #fff;
  background-color: #82061b;
  font-size: 16px;
  padding: 8px 16px 12px;
  border-radius: 16px;
}

@media screen and (min-width: 994px) {
  .main-intro-title {
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.7;
    margin-bottom: 36px;
  }
  .main-intro-desc {
    font-size: 1.5rem;
    margin-bottom: 48px;
  }

  .main-intro-desc span {
    color: #004845;
  }
  .main-intro-link {
    font-size: 2rem;
    margin-bottom: 48px;
    padding: 12px 48px 16px;
  }

  .main-intro-img {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    margin: 0 auto;
    background-image: url("../../img/main/main-img-lg.jpg");
    margin-bottom: 36px;
  }
}
