.intro-bar {
  background-color: #004845;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0 12px;
  margin-bottom: 24px;
}

.intro-bar-logo svg {
  fill: #fff;
  margin-right: 10px;
}

.intro-bar-text {
  color: #fff;
  font-size: 19px;
}

.kind-contact-text {
  text-align: center;
}

.kind-contact-text-link {
  display: block;
  margin: 0 auto;
  width: 75%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #82061b;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
}

.kind-contact-text h5 {
  font-size: 12px;
  line-height: 1.1666666667;
  margin-bottom: 28px;
}

@media screen and (min-width: 994px) {
  .intro-bar {
    margin-bottom: 48px;
  }

  .intro-bar-logo svg {
    transform: scale(1.6);
    margin-right: 24px;
    transform: translateY(2px) scale(1.6);
  }
  .intro-bar-text {
    font-size: 40px;
  }

  .kind-contact-text-link {
    margin-top: 48px;
    font-size: 39px;
    white-space: nowrap;
    padding: 0;
    padding-bottom: 3px;
    width: 50%;
    border-radius: 40px;
  }

  .kind-contact-text h5 {
    margin-bottom: 48px;
    font-size: 28px;
  }
}
