.facility-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-image: url('../../img/facility-intro/facility-main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.facility-container-box-item-text-title {
  font-size: 20px;
  color: #004845;
  letter-spacing: -0.02em;
  margin-bottom: 5px;
  font-weight: 500;
}

.facility-container-box-item-text-title br {
  display: none;
}

.facility-container-box-item-text-desc {
  font-size: 12px;
  letter-spacing: 0;
  margin-bottom: 10px;
  color: #707070;
}

.facility-container-box-item:first-child {
  margin-top: 25px;
}

.facility-container-box-item-img1,
.facility-container-box-item-img2,
.facility-container-box-item-img3,
.facility-container-box-item-img4 {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  border: 0;
  margin-bottom: 30px;
}

.facility-container-box-item-img4 {
  margin-bottom: 20px;
}

.facility-container-box-item-img1 {
  background-image: url('../../img/facility-intro/facility-items/facility-1.jpg');
}
.facility-container-box-item-img2 {
  background-image: url('../../img/facility-intro/facility-items/facility-4.jpg');
}
.facility-container-box-item-img3 {
  background-image: url('../../img/facility-intro/facility-items/facility-7.jpg');
}
.facility-container-box-item-img4 {
  background-image: url('../../img/facility-intro/facility-items/facility-5.jpg');
}

.facility-container-blog-title {
  background-color: #004845;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 12px 24px;
  margin-bottom: 16px;
}

.facility-container-blog-list {
  padding: 0 10px;
  margin-bottom: 24px;
}

.facility-container-blog-list-item {
  margin-bottom: 12px;
}
.facility-container-blog-list-item:last-child {
  margin-bottom: 0;
}
.facility-container-blog-list a {
  display: inline-block;
  font-size: 12px;
  color: #707070;
  text-decoration: underline;
  margin-left: 10px;
}

.facility-container-blog-list-item span {
  font-size: 16px;
  color: #004845;
}

.facility-container-info h1 {
  font-size: 18px;
  background-color: #82061b;
  color: #fff;
  text-align: center;
  border-radius: 24px;
  padding: 12px 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 994px) {
  .facility-container-main-img {
    background-image: url('../../img/facility-intro/facility-main-img-desk.jpg');
  }

  .facility-container-box-item:first-child {
    display: none;
  }

  .facility-container-box-items {
    display: flex;
    padding: 12px;
    margin-bottom: 48px;
  }
  .facility-container-box-item {
    margin-right: 33px;
    width: 33%;
  }
  .facility-container-box-item:last-child {
    margin-right: 0;
  }

  .facility-container-box-item-text-title {
    text-align: center;
    font-size: 28px;
    padding: 0 12px;
    margin-bottom: 18px;
  }

  .facility-container-box-item-text-title br {
    display: block;
  }

  .facility-container-box-item-text-desc {
    font-size: 14px;
    line-height: 1.3684210526;
    padding: 0 18px;
    letter-spacing: -0.05em;
  }

  .facility-container-blog-title {
    width: 50%;
    margin: 48px auto;
    font-size: 33px;
  }

  .facility-container-blog-list {
    margin-bottom: 48px;
  }

  .facility-container-blog-list-item {
    margin-bottom: 32px;
  }

  .facility-container-blog-list-item:last-child {
    margin-bottom: 0;
  }

  .facility-container-blog-list a {
    font-size: 28px;
  }

  .facility-container-blog-list-item span {
    font-size: 30px;
  }

  .facility-container-info h1 {
    font-size: 33px;
    width: 50%;
    margin: 56px auto 70px;
  }
}
