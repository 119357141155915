.header-logo {
  padding: 20px 0 16px;
}

.header-logo-img {
  width: auto;
  height: 0;
  padding-bottom: 20%;
  background-image: url('../../img/header/header-logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 8px;
}

.header-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #707070;
  overflow-x: hidden;
  margin-bottom: 16px;
}

.header-menu-list {
  display: flex;
  padding: 0 12px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.header-menu-list::-webkit-scrollbar {
  display: none;
}

.header-menu-item {
  margin-right: 24px;
}
.header-menu-item:last-child {
  margin-right: 0;
}

.header-menu-link {
  display: block;
  margin-top: 16px;
  font-size: 0.8em;
  white-space: nowrap;
}

/* kakaotalk */

.kakaotalk-icon,
.phone-icon {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 100;
  opacity: 0.8;
}

.kakaotalk-icon {
  position: fixed;
  right: 8px;
  top: 140px;
  background-image: url('../../img/detalis/kakao.png');
  border-radius: 20%;
}
.phone-icon {
  width: 45px;
  height: 45px;
  position: fixed;
  right: 7px;
  top: 187px;
  background-image: url('../../img/detalis/call-w.webp');
}

@media screen and (min-width: 994px) {
  .header-logo-img {
    padding-bottom: 5%;
  }

  .header-menu {
    border-top: 1px solid #707070;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-menu-list {
    width: 100%;
    justify-content: space-between;
    padding: 0 12px;
  }

  .header-menu-item {
    flex-grow: 1;
  }

  .header-menu-item a {
    display: block;
    font-size: 1.3rem;
    padding: 1em 0;
    text-align: center;
    margin-top: 0;
  }

  .header-menu-item a:hover {
    background-color: cadetblue;
    color: #fff;
  }

  .kakaotalk-icon {
    width: 80px;
    height: 80px;
    right: 47px;
    top: 200px;
    opacity: 0.6;
  }

  .phone-icon {
    width: 80px;
    height: 80px;
    right: 45px;
    top: 290px;
    opacity: 0.6;
  }

  .phone-icon:hover .phone-icon-hover {
    width: 300px;
    height: 200px;
    background-image: url('../../img/detalis/call-hover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    right: 120px;
    top: 310px;
  }
}
