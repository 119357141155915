.facility-intro {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 3px;
  white-space: nowrap;
}

.facility-intro-text {
  margin-right: 10px;
}

.facility-intro-title {
  font-size: 1.2em;
  color: #004845;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 500;
}

.facility-intro-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 12px;
}

.facility-intro-sub-bar {
  font-size: 8px;
  display: inline-block;
  background-color: #004845;
  padding: 8px 14px;
  border-radius: 16px;
  color: #fff;
}

.facility-intro-sub-notice {
  display: block;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 24px;
  text-align: center;
}

.facility-intro-img {
  background-image: url('../../img/facility-intro/facility-intro-logo.jpg');
  width: 50%;
  height: 0;
  padding-bottom: 35%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

@media screen and (min-width: 994px) {
  .facility-intro {
    margin-bottom: 70px;
  }
  .facility-intro-title {
    font-size: 36px;
    letter-spacing: -0.03em;
  }
  .facility-intro-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    margin-bottom: 36px;
  }
  .facility-intro-sub-bar {
    font-size: 24px;
    padding: 18px 36px;
  }
  .facility-intro-sub-notice {
    display: none;
  }

  .facility-img {
    padding: 0 24px;
  }

  .facility-intro-img {
    padding-bottom: 30%;
  }
}

/* Facility-img */

.facility-img {
  padding: 0 12px;
}

.facility-img-text-title {
  font-size: 18px;
  letter-spacing: -0.022em;
  color: #004845;
  line-height: 1.4;
  margin-bottom: 10px;
}

.facility-img-text-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 10px;
}

.facility-img-img {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  justify-content: center;
}

.facility-img-img-item1,
.facility-img-img-item2,
.facility-img-img-item3,
.facility-img-img-item4,
.facility-img-img-item5,
.facility-img-img-item6 {
  width: 48%;
  height: 0;
  padding-bottom: 30%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.facility-img-img-item1 {
  background-image: url('../../img/facility-intro/facility-items/facility-1.jpg');
  margin-right: 8px;
  margin-bottom: 8px;
}
.facility-img-img-item2 {
  background-image: url('../../img/facility-intro/facility-items/facility-2.jpg');
  margin-bottom: 8px;
}
.facility-img-img-item3 {
  background-image: url('../../img/facility-intro/facility-items/facility-3.jpg');
  margin-right: 8px;
  margin-bottom: 8px;
}
.facility-img-img-item4 {
  background-image: url('../../img/facility-intro/facility-items/facility-4.jpg');
  margin-bottom: 8px;
}
.facility-img-img-item5 {
  background-image: url('../../img/facility-intro/facility-items/facility-5.jpg');
  margin-right: 8px;
}
.facility-img-img-item6 {
  background-image: url('../../img/facility-intro/facility-items/facility-6.jpg');
}

@media screen and (min-width: 994px) {
  .facility-img {
    background-color: #eef2f6;
    padding: 36px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 48px;
  }

  .facility-img-text-title {
    font-size: 44px;
  }

  .facility-img-text-desc {
    font-size: 30px;
    margin-bottom: 24px;
  }

  .facility-img-img-item1,
  .facility-img-img-item2,
  .facility-img-img-item3,
  .facility-img-img-item4,
  .facility-img-img-item5,
  .facility-img-img-item6 {
    padding-bottom: 35%;
  }

  .facility-img-img-item1 {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .facility-img-img-item2 {
    margin-bottom: 15px;
  }
  .facility-img-img-item3 {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .facility-img-img-item4 {
    margin-bottom: 15px;
  }
  .facility-img-img-item5 {
    margin-right: 15px;
  }
}
