/* .footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.footer-logo {
  margin: 0 auto;
  width: 120px;
  height: 0;
  padding-bottom: 30%;
  background-image: url('../../img/detalis/jin-logo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 12px;
}

.footer-info {
  background-color: #383838;
  color: #777777;
  font-size: 11px;
  line-height: 1.4545454545;
  letter-spacing: 0;
  padding: 13px 10px;
}

@media screen and (min-width: 994px) {
  .footer-logo {
    padding-bottom: 10%;
  }
}
