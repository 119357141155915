.care-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 90%;
  background-image: url('../../img/care-intro/care-main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.care-container-box-item {
  margin-bottom: 24px;
}

.care-container-box-item-img1,
.care-container-box-item-img2,
.care-container-box-item-img3,
.care-container-box-item-img4,
.care-container-box-item-img5,
.care-container-box-item-img6,
.care-container-box-item-img7 {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.care-container-box-item-img1 {
  background-image: url('../../img/care-intro/care-2.jpg');
}
.care-container-box-item-img2 {
  background-image: url('../../img/care-intro/care-1.jpg');
}
.care-container-box-item-img3 {
  background-image: url('../../img/care-intro/care-3.jpg');
}
.care-container-box-item-img4 {
  background-image: url('../../img/care-intro/care-4.jpg');
}
.care-container-box-item-img5 {
  background-image: url('../../img/care-intro/care-5.jpg');
}
.care-container-box-item-img6 {
  background-image: url('../../img/care-intro/care-6.jpg');
}
.care-container-box-item-img7 {
  background-image: url('../../img/care-intro/care-7.jpg');
}

.care-container-box-item p {
  margin-top: 3px;
  text-align: center;
  color: #004845;
  font-size: 10px;
  font-weight: 500;
}

.care-slick-container {
  display: none;
}

@media screen and (min-width: 994px) {
  .care-slick-container-main {
    margin-bottom: 100px;
  }
  .care-container-main-img {
    padding-bottom: 60%;
    background-image: url('../../img/care-intro/care-main-img-desk.jpg');
    margin-bottom: 70px;
  }

  .care-slick-container {
    display: block;
  }

  .care-container-box-items {
    display: none;
  }
}
