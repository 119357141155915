.care-intro {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 3px;
  white-space: nowrap;
}

.care-intro-text {
  margin-right: 10px;
}

.care-intro-title {
  font-size: 1.2em;
  color: #004845;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 500;
}

.care-intro-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 12px;
}

.care-intro-sub-bar {
  font-size: 8px;
  display: inline-block;
  background-color: #004845;
  padding: 8px 14px;
  border-radius: 16px;
  color: #fff;
}

.care-intro-sub-notice {
  display: block;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 12px;
  text-align: center;
}

.care-intro-img {
  background-image: url('../../img/care-intro/care-intro-logo.jpg');
  width: 50%;
  height: 0;
  padding-bottom: 45%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

@media screen and (min-width: 994px) {
  .care-intro {
    margin-bottom: 70px;
  }

  .care-intro-title {
    font-size: 36px;
    letter-spacing: -0.03em;
  }

  .care-intro-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    margin-bottom: 36px;
  }

  .care-intro-sub-bar {
    font-size: 24px;
    padding: 18px 36px;
  }

  .care-intro-sub-notice {
    display: none;
  }

  .care-intro-img {
    padding-bottom: 30%;
  }
}
