.review-box,
.review-box-main {
  background-color: #eef2f6;
  margin-bottom: 24px;
  padding: 12px 18px;
}

.review-box-id,
.review-box-id-main {
  display: flex;
  margin-bottom: 12px;
}

.review-box-id-avatar,
.review-box-id-avatar-main {
  transform: scale(1.5);
  margin-right: 12px;
  margin-left: 5px;
}

.review-box-id-name,
.review-box-id-name-main {
  font-size: 20px;
}

.review-box-stars,
.review-box-stars-main {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.review-box-stars-star,
.review-box-stars-star-main {
  margin-right: 10px;
}

.review-box-stars-score,
.review-box-stars-score-main {
  margin-bottom: 2px;
  font-size: 22px;
}

.review-box-date,
.review-box-date-main {
  font-size: 12px;
  color: #676767;
  margin-bottom: 12px;
}

.review-box-text,
.review-box-text-main {
  font-size: 12px;
  color: #676767;
}

@media screen and (min-width: 994px) {
  .review-box-main-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 36px;
  }

  .review-box,
  .review-box-main {
    padding: 24px 18px;
  }

  .review-box-text,
  .review-box-text-main {
    font-size: 20px;
  }

  .review-box-main {
    display: block;
    width: 48%;
  }

  .review-box-main:nth-child(odd) {
    margin-right: 12px;
  }
}
