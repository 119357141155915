.rehabilitation-slick-container,
.care-slick-container-main {
  margin: 0 8px 56px;
  padding: 0 5px;
}

.slick-slide img {
  margin: 0 auto;
  border-radius: 30px;
}

.slick-track {
  margin-bottom: 48px;
}

.slick-track img {
  width: 100%;
}

.slick-dots li {
  margin-right: 30px;
}

.slick-dots li a {
  display: block;
}

.slick-dots li a img {
  width: 50px;
  height: 50px;
}

/* care */

.care-slick-container-main p,
.rehabilitation-slick-container p {
  margin-top: 10px;
  text-align: center;
  color: #004845;
}
.care-slick-container-main .slick-track {
  margin-bottom: 20px;
}

.care-slick {
  display: flex;
}
.care-slick li {
  margin-right: 10px;
}

.care-slick li a img {
  width: 30px;
  height: 30px;
}

@media screen and (min-width: 994px) {
  .rehabilitation-slick-container,
  .care-slick-container-main {
    padding: 0 48px;
  }
  .slick-next {
    margin-right: 160px;
  }
  .slick-prev {
    margin-left: 150px;
  }

  .slick-slide img {
    margin: 0 auto;
    border-radius: 30px;
  }

  .slick-track img {
    width: 50%;
  }
  .slick-arrow:before {
    color: #000;
    font-size: 33px;
    color: #004845;
  }

  .slick-dots li a img {
    width: 50px;
    height: 50px;
  }

  .care-slick-container-main p,
  .rehabilitation-slick-container p {
    font-size: 16px;
    font-weight: 500;
  }

  .care-slick-container-main .slick-track {
    margin-bottom: 50px;
  }

  .care-slick li {
    margin-right: 30px;
  }

  .care-slick li a img {
    width: 50px;
    height: 50px;
  }
}
